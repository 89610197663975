
const Spinner = () => (
    <svg className="animate-spin -ml-1 mr-3 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.313 1.353 6.314 3.545 8.457l2.455-3.166z"></path>
    </svg>
);
export default function PrimaryButton({ className = '', disabled, processing, children, ...props }) {
    return (
        <button
            {...props}
            className={
                `inline-flex items-center px-4 py-3 bg-uhl-light-blue border border-transparent rounded-md font-semibold text-xs text-white uppercase hover:bg-[#035EDD] focus:bg-gray-700 active:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition ease-in-out duration-150 ${
                    disabled && 'opacity-50'
                } ` + className
            }
            disabled={disabled}
        >
            {processing && <Spinner />}
            {children}
        </button>
    );
}
